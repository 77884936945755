@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');
@import './scss/variables';
@import './scss/Nav';
@import './scss/Paginator';
@import './scss/Toast';
@import './scss/About';
@import './scss/Techs';
@import './scss/Projects';
@import './scss/Contact';

body {
  margin: 0;
  padding: 0;
  background-color: $main-color;
  font-family: 'Titillium Web', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Teko', 'Titillium Web', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: 500;
}

#scrollable-content {
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }  
}

.App {
  #logo-watermark {
    position: absolute;
    z-index: -10;
    right: -40%;
    bottom: -10%;
    transform: translateX(40%);
    transform: translateY(10%);
    height: 100%;
    width: 100%;
    opacity: 0.12;

    @media only screen and (max-width: 640px){
      bottom: -25%;
    }
  }

  h2.text-watermark {
    color: $text-watermark;
    font-size: 300px;
    z-index: -10;
    top: 0;
    left: 2%;

    @media only screen and (max-width: 640px){
      top: 10%;
      font-size: 144px;
    }
  }
}

section {
  @apply w-screen h-full pointer-events-none bg-transparent;
}

.btn-primary {
  background-color: $accent-color;
  border-radius: 0;
  letter-spacing: 0.12rem;
  color: $tertiary-color;
  position: relative;
  margin-left: 22px;
  transition: background-color, color 0.3s ease-in-out;

  &:hover {
    background-color: $tertiary-color;
    color: $main-color;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    width: 30px;
    background-color: inherit;
    top: 0;
    left: -10px;
    bottom: 0;
    transform: skewX(-22deg);
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -2;
    width: 30px;
    background-color: inherit;
    top: 0;
    right: -10px;
    bottom: 0;
    transform: skewX(-22deg);
  }
}

.sub-header {
  font-family: "Tittillium Web" sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.12rem;
  background-color: $accent-color;
  color: $tertiary-color;
  position: relative;
  margin-left: 20px;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    width: 30px;
    background-color: $accent-color;
    top: 0;
    left: -10px;
    bottom: 0;
    transform: skewX(-22deg);
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -2;
    width: 30px;
    background-color: $accent-color;
    top: 0;
    right: -10px;
    bottom: 0;
    transform: skewX(-22deg);
  }
}