@import url(https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap);

:export{
  main-color:#010106;
  secondary-color:#4a4a4a;
  tertiary-color:#f0eff4;
  accent-color:#b20000;
  inactive-link-color:rgba(240,239,244,0.301961);
  text-watermark:rgba(240,239,244,0.05)
}

nav{
  height:90px;
  background-color:#010106
}

nav .container #brand-logo{
  height:60px;
  width:80px
}

@media only screen and (max-width: 640px){
  nav .container #brand-logo{
    height:50px;
    width:70px
  }
}

@media only screen and (max-width: 640px){
  nav .container #main-nav{
    display:none
  }
}

nav .container #main-nav #indicator{
  position:absolute;
  bottom:-0.8rem;
  width:18px;
  border-bottom:3px solid #b20000;
  transition:left 0.35s ease-in-out
}

@media only screen and (max-width: 640px){
  nav .container #main-nav #indicator{
    display:none
  }
}

nav .container #main-nav .link{
  cursor:pointer;
  line-height:1;
  position:relative;
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
  color:rgba(240,239,244,0.301961);
  font-size:18px;
  font-family:"Titillium Web", sans-serif;
  font-weight:normal;
  letter-spacing:0.12rem;
  transition:all 0.3s cubic-bezier(0, 0.5, 0, 1)
}

nav .container #main-nav .link:hover{
  color:#f0eff4
}

nav .container #main-nav .link.active{
  color:#f0eff4;
  transition:width 0.3s cubic-bezier(0, 0.5, 0, 1),border-color 0.3s cubic-bezier(0, 0.5, 0, 1)
}

nav .container #main-nav .link:hover::after{
  width:18px;
  border-color:#b20000
}

nav .container .menu-icon-container{
  display:none
}

@media only screen and (max-width: 640px){
  nav .container .menu-icon-container{
    display:flex
  }
}

nav .container #mobile-nav{
  position:absolute;
  height:100vh;
  width:100vw;
  left:105%;
  top:0;
  bottom:0;
  z-index:-2;
  background-color:#010106;
  transition:left 0.3s cubic-bezier(0, 0.5, 0, 1);
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center
}

@media only screen and (min-width: 640px){
  nav .container #mobile-nav{
    left:120%
  }
}

nav .container #mobile-nav.open{
  left:0
}

nav .container #mobile-nav .link{
  cursor:pointer;
  line-height:1;
  position:relative;
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
  color:rgba(240,239,244,0.301961);
  font-size:36px;
  font-family:"Titillium Web", sans-serif;
  font-weight:normal;
  letter-spacing:0.12rem;
  transition:all 0.3s cubic-bezier(0, 0.5, 0, 1)
}

nav .container #mobile-nav .link:hover{
  color:#f0eff4
}

nav .container #mobile-nav .link.active{
  color:#f0eff4;
  transition:width 0.3s cubic-bezier(0, 0.5, 0, 1),border-color 0.3s cubic-bezier(0, 0.5, 0, 1);
  position:relative
}

nav .container #mobile-nav .link.active::after{
  content:"";
  background-color:#b20000;
  position:absolute;
  height:3px;
  width:18px;
  left:50%;
  bottom:-1rem;
  transform:translateX(-50%)
}

nav .container #mobile-nav .link:hover::after{
  width:18px;
  border-color:#b20000
}

#paginator{
  position:relative;
  height:95px;
  margin-bottom:5%
}

@media only screen and (max-width: 640px){
  #paginator{
    height:55px
  }
}

#paginator #page-indicator{
  width:65px;
  z-index:-2;
  position:absolute;
  left:0;
  top:0;
  bottom:0;
  transform:skewX(-25deg) translateX(-50%);
  background-color:#b20000;
  transition:left 0.3s cubic-bezier(0, 0.5, 0, 1)
}

@media only screen and (max-width: 640px){
  #paginator #page-indicator{
    width:45px
  }
}

#paginator .icon-container{
  display:flex;
  justify-content:center;
  width:65px
}

@media only screen and (max-width: 640px){
  #paginator .icon-container{
    width:45px
  }
}

#paginator .icon-container .icon:hover{
  color:#f0eff4
}

#paginator .page-container{
  width:65px
}

@media only screen and (max-width: 640px){
  #paginator .page-container{
    width:45px
  }
}

#paginator .page-container .page-number{
  color:rgba(240,239,244,0.301961);
  font-family:"Teko", sans-serif;
  font-size:30px;
  font-weight:300;
  line-height:normal;
  cursor:pointer
}

@media only screen and (max-width: 640px){
  #paginator .page-container .page-number{
    font-size:25px
  }
}

#paginator .page-container .page-number:hover{
  color:#f0eff4
}

#paginator .page-container .page-number.active{
  color:#f0eff4
}

.toast{
  position:absolute;
  z-index:10;
  top:15%;
  color:#f0eff4;
  background-color:#4a4a4a;
  width:-webkit-max-content;
  width:-moz-max-content;
  width:max-content;
  padding:8px 10px;
  font-size:0.95rem;
  letter-spacing:0.05rem;
  transition:all 0.5s cubic-bezier(0, 0.5, 0, 1)
}

.toast.show{
  right:4%
}

.toast.hide{
  right:-50%
}

.toast::before{
  content:"";
  position:absolute;
  z-index:-2;
  width:30px;
  background-color:#4a4a4a;
  top:0;
  left:-10px;
  bottom:0;
  transform:skewX(-22deg)
}

#about{
  position:relative
}

@media only screen and (max-width: 640px) and (orientation: portrait){
  #about .container{
    padding-top:42%
  }
}

@media only screen and (max-height: 414px) and (max-width: 823px) and (orientation: landscape){
  #about .container{
    justify-content:flex-start
  }
}

#about .container h1{
  color:rgba(240,239,244,0.8);
  font-size:6.5rem;
  line-height:6.5rem
}

@media only screen and (max-width: 640px){
  #about .container h1{
    font-size:4rem;
    line-height:4rem
  }
}

#about .container h1 span{
  color:#b20000
}

#about .container h3{
  color:rgba(240,239,244,0.8)
}

@media only screen and (max-width: 640px){
  #about .container h3{
    font-size:2rem;
    line-height:2rem
  }
}

@media only screen and (max-width: 823px) and (orientation: landscape){
  #about .container h3{
    margin-top:25%
  }
}

#about .container .intro-text{
  color:rgba(240,239,244,0.9)
}

#techs{
  position:relative
}

#techs .container{
  padding-top:0
}

@media only screen and (max-width: 640px) and (orientation: portrait){
  #techs .container{
    padding-top:30%
  }
}

@media only screen and (min-width: 640px) and (max-width: 823px) and (orientation: landscape){
  #techs .container{
    margin-top:12%;
    height:auto;
    justify-content:center;
    align-items:center
  }
}

@media only screen and (width: 280px) and (height: 653px) and (orientation: portrait){
  #techs .container{
    padding-top:40%
  }
}

@media only screen and (width: 568px) and (height: 320px) and (orientation: landscape){
  #techs .container{
    padding-top:18%;
    height:auto;
    justify-content:space-between
  }
}

#techs .container .tabs{
  position:relative
}

#techs .container .tabs .tab{
  cursor:pointer;
  font-size:1.125rem;
  line-height:1.75rem;
  position:relative;
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
  transition:transform 0.7s cubic-bezier(0, 0.5, 0, 1),top 0.7s cubic-bezier(0, 0.5, 0, 1);
  color:rgba(240,239,244,0.301961);
  font-family:'Teko', sans-serif;
  font-size:48px;
  line-height:48px;
  letter-spacing:0.12rem
}

@media only screen and (max-width: 640px){
  #techs .container .tabs .tab{
    font-size:2rem;
    line-height:2rem
  }
}

#techs .container .tabs .tab:hover{
  color:#f0eff4
}

#techs .container .tabs .tab:nth-child(2){
  margin:20px 0
}

@media only screen and (max-width: 640px){
  #techs .container .tabs .tab:nth-child(2){
    margin:10px 0
  }
}

#techs .container .tabs .tab.active{
  color:#f0eff4
}

#techs .container .tabs .tab.active::before{
  content:"";
  background-color:#b20000;
  z-index:-2;
  width:6px;
  height:54px;
  position:absolute;
  left:-20px;
  top:-8px;
  transition:all 0.7s cubic-bezier(0, 0.5, 0, 1)
}

@media only screen and (max-width: 640px){
  #techs .container .tabs .tab.active::before{
    top:-6px;
    height:2.5rem
  }
}

#projects{
  position:relative;
  width:100%;
  height:100%
}

#projects .project-container{
  padding-top:30px
}

@media only screen and (min-width: 768px){
  #projects .project-container{
    padding-top:100px
  }
}

@media only screen and (max-width: 823px) and (orientation: landscape){
  #projects .project-container{
    padding-top:100px;
    height:auto
  }
}

#projects .project-container .project-item{
  width:100vw;
  height:100%
}

@media only screen and (min-width: 320px) and (orientation: landscape){
  #projects .project-container .project-item .container{
    justify-content:space-between
  }
}

@media only screen and (min-width: 812px) and (orientation: landscape){
  #projects .project-container .project-item .container{
    margin-bottom:50px
  }
}

#projects .project-container .project-item .container .project-info-container h1{
  font-size:3.8rem;
  line-height:3.8rem;
  letter-spacing:0.12rem;
  color:#f0eff4
}

@media only screen and (min-width: 640px) and (max-width: 768px){
  #projects .project-container .project-item .container .project-info-container h1{
    font-size:3rem;
    line-height:3rem
  }
}

@media only screen and (max-width: 640px){
  #projects .project-container .project-item .container .project-info-container h1{
    font-size:2rem;
    line-height:2rem
  }
}

#projects .project-container .project-item .container .project-info-container h1 .project-status{
  position:relative;
  width:1rem;
  height:1rem;
  z-index:2
}

#projects .project-container .project-item .container .project-info-container p{
  color:rgba(240,239,244,0.8)
}

@media only screen and (min-width: 320px) and (orientation: landscape){
  #projects .project-container .project-item .container .project-image-container{
    margin-bottom:50px
  }
}

#projects .project-container .project-item .container .project-image-container img{
  z-index:-1
}

@media only screen and (min-width: 738px) and (max-width: 823px) and (orientation: landscape){
  #contact .container{
    margin-top:10%
  }
}

@media only screen and (width: 320px){
  #contact .container .social-links-container{
    padding-top:25%
  }
}

@media only screen and (height: 320px) and (width: 568px) and (orientation: landscape){
  #contact .container .social-links-container{
    padding-top:60%
  }
}

@media only screen and (min-width: 640px) and (max-width: 736px) and (orientation: landscape){
  #contact .container .social-links-container{
    padding-top:50%
  }
}

@media only screen and (height: 540px) and (width: 720px) and (orientation: landscape){
  #contact .container .social-links-container{
    padding-top:25%
  }
}

@media only screen and (height: 280px) and (width: 653px) and (orientation: landscape){
  #contact .container .social-links-container{
    padding-top:70%
  }
}

#contact .container .social-links-container h1{
  color:#f0eff4;
  font-size:3.8rem;
  line-height:3.8rem;
  letter-spacing:0.12rem
}

#contact .container .social-links-container .links-container .link-item a{
  cursor:pointer;
  color:#f0eff4;
  margin-left:20px;
  letter-spacing:0.1rem;
  transition:color 0.3s cubic-bezier(0, 0.5, 0, 1)
}

#contact .container .social-links-container .links-container .link-item a:hover{
  --tw-text-opacity:1;
  color:rgba(220, 38, 38, var(--tw-text-opacity))
}

#contact .container .social-links-container .links-container :nth-child(2){
  margin:10px 0
}

@media only screen and (min-width: 768px) and (max-width: 825px) and (orientation: landscape){
  #contact .container .contact-form-container{
    margin-top:100px
  }
}

#contact .container .contact-form-container #contact-form input[type=text],#contact .container .contact-form-container #contact-form input[type=email]{
  font-weight:500;
  color:#010106;
  border-radius:0
}

#contact .container .contact-form-container #contact-form input[type=text]::-webkit-input-placeholder, #contact .container .contact-form-container #contact-form input[type=email]::-webkit-input-placeholder{
  color:#0101065d
}

#contact .container .contact-form-container #contact-form input[type=text]:-ms-input-placeholder, #contact .container .contact-form-container #contact-form input[type=email]:-ms-input-placeholder{
  color:#0101065d
}

#contact .container .contact-form-container #contact-form input[type=text]::placeholder,#contact .container .contact-form-container #contact-form input[type=email]::placeholder{
  color:#0101065d
}

#contact .container .contact-form-container #contact-form textarea{
  font-weight:500;
  color:#010106;
  border-radius:0
}

#contact .container .contact-form-container #contact-form textarea::-webkit-input-placeholder{
  color:#0101065d
}

#contact .container .contact-form-container #contact-form textarea:-ms-input-placeholder{
  color:#0101065d
}

#contact .container .contact-form-container #contact-form textarea::placeholder{
  color:#0101065d
}

#contact .container .contact-form-container #contact-form .send-btn{
  background-color:#b20000;
  border-radius:0;
  color:#f0eff4;
  letter-spacing:0.12rem;
  position:relative;
  margin-right:22px;
  transition:background-color, color 0.3s ease-in-out
}

#contact .container .contact-form-container #contact-form .send-btn:hover{
  background-color:#f0eff4;
  color:#010106
}

#contact .container .contact-form-container #contact-form .send-btn::before{
  content:"";
  position:absolute;
  z-index:-2;
  width:30px;
  background-color:inherit;
  top:0;
  left:-10px;
  bottom:0;
  transform:skewX(-22deg)
}

#contact .container .contact-form-container #contact-form .send-btn::after{
  content:"";
  position:absolute;
  z-index:-2;
  width:30px;
  background-color:inherit;
  top:0;
  right:-10px;
  bottom:0;
  transform:skewX(-22deg)
}

body{
  margin:0;
  padding:0;
  background-color:#010106;
  font-family:'Titillium Web', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale
}

h1,h2,h3,h4,h5,h6{
  font-family:'Teko', 'Titillium Web', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight:500
}

#scrollable-content{
  -ms-overflow-style:none;
  scrollbar-width:none
}

#scrollable-content::-webkit-scrollbar{
  display:none
}

.App #logo-watermark{
  position:absolute;
  z-index:-10;
  right:-40%;
  bottom:-10%;
  transform:translateX(40%);
  transform:translateY(10%);
  height:100%;
  width:100%;
  opacity:0.12
}

@media only screen and (max-width: 640px){
  .App #logo-watermark{
    bottom:-25%
  }
}

.App h2.text-watermark{
  color:rgba(240,239,244,0.05);
  font-size:300px;
  z-index:-10;
  top:0;
  left:2%
}

@media only screen and (max-width: 640px){
  .App h2.text-watermark{
    top:10%;
    font-size:144px
  }
}

section{
  background-color:transparent;
  height:100%;
  pointer-events:none;
  width:100vw
}

.btn-primary{
  background-color:#b20000;
  border-radius:0;
  letter-spacing:0.12rem;
  color:#f0eff4;
  position:relative;
  margin-left:22px;
  transition:background-color, color 0.3s ease-in-out
}

.btn-primary:hover{
  background-color:#f0eff4;
  color:#010106
}

.btn-primary::before{
  content:"";
  position:absolute;
  z-index:-2;
  width:30px;
  background-color:inherit;
  top:0;
  left:-10px;
  bottom:0;
  transform:skewX(-22deg)
}

.btn-primary::after{
  content:"";
  position:absolute;
  z-index:-2;
  width:30px;
  background-color:inherit;
  top:0;
  right:-10px;
  bottom:0;
  transform:skewX(-22deg)
}

.sub-header{
  font-family:"Tittillium Web" sans-serif;
  font-size:1.3rem;
  font-weight:500;
  letter-spacing:0.12rem;
  background-color:#b20000;
  color:#f0eff4;
  position:relative;
  margin-left:20px
}

.sub-header::before{
  content:"";
  position:absolute;
  z-index:-2;
  width:30px;
  background-color:#b20000;
  top:0;
  left:-10px;
  bottom:0;
  transform:skewX(-22deg)
}

.sub-header::after{
  content:"";
  position:absolute;
  z-index:-2;
  width:30px;
  background-color:#b20000;
  top:0;
  right:-10px;
  bottom:0;
  transform:skewX(-22deg)
}

