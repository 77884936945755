#paginator {
  position: relative;
  height: 95px;
  margin-bottom: 5%;

  @media only screen and (max-width: 640px){
    height: 55px;
  }

  #page-indicator {
    width: 65px;
    z-index: -2;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    transform: skewX(-25deg) translateX(-50%);
    background-color: $accent-color;
    transition: left 0.3s cubic-bezier(0,.5,0,1);

    @media only screen and (max-width: 640px){
      width: 45px;
    }
  }

  .icon-container {
    display: flex;
    justify-content: center;
    width: 65px;

    @media only screen and (max-width: 640px){
      width: 45px;
    }

    .icon:hover {
      color: $tertiary-color;
    }
  }

  .page-container {
    width: 65px;

    @media only screen and (max-width: 640px){
      width: 45px;
    }

    .page-number {
      color: $inactive-link-color;
      font-family: "Teko", sans-serif;
      font-size: 30px;
      font-weight: 300;
      line-height: normal;
      cursor: pointer;

      @media only screen and (max-width: 640px){
        font-size: 25px;
      }
      
      &:hover {
        color: $tertiary-color;
      }

      &.active {
        color: $tertiary-color;
      }
    }
  }
}