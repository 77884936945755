#projects {
  position: relative;
  width: 100%;
  height: 100%;

  .project-container {
    padding-top: 30px;

    @media only screen and (min-width: 768px) {
      padding-top: 100px;
    }

    @media only screen and (max-width: 823px) and (orientation: landscape){
      padding-top: 100px;
      height: auto;
    }

    .project-item {
      width: 100vw;
      height: 100%;

      .container {
        @media only screen and (min-width: 320px) and (orientation: landscape){
          justify-content: space-between;
        }
        
        @media only screen and (min-width: 812px) and (orientation: landscape){
          margin-bottom: 50px;
        }

        .project-info-container {
          h1 {
            font-size: 3.8rem;
            line-height: 3.8rem;
            letter-spacing: 0.12rem;
            color: $tertiary-color;
  
            @media only screen and (min-width: 640px) and (max-width: 768px){
              font-size: 3rem;
              line-height: 3rem;
            }
  
            @media only screen and (max-width: 640px){
              font-size: 2rem;
              line-height: 2rem;
            }

            .project-status {
              position: relative;
              width: 1rem;
              height: 1rem;
              z-index: 2;
            }
          }
    
          p {
            color: rgba(240, 239, 244, 0.8);
          }
        }
    
        .project-image-container {
          @media only screen and (min-width: 320px) and (orientation: landscape){
            margin-bottom: 50px;
          }

          img {
            z-index: -1;
          }
        }
      }
    }
    
  }
}