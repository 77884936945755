#about {
  position: relative;
  .container {    
    @media only screen and (max-width: 640px) and (orientation: portrait){
      padding-top: 42%;
    }
    
    @media only screen and (max-height: 414px) and (max-width: 823px) and (orientation: landscape){
      justify-content: flex-start;
    }
    
    h1 {
      color: rgba(240, 239, 244, 0.8);
      font-size: 6.5rem;
      line-height: 6.5rem;
      
      
      @media only screen and (max-width: 640px){
        font-size: 4rem;
        line-height: 4rem;
      }

      span {
        color: $accent-color;
      }
    }

    h3 {
      color: rgba(240, 239, 244, 0.8);
      
      @media only screen and (max-width: 640px){
        font-size: 2rem;
        line-height: 2rem;
      }

      @media only screen and (max-width: 823px) and (orientation: landscape){
        margin-top: 25%;
      }
    }

    .intro-text {
      color:  rgba(240, 239, 244, 0.9);
    }
  }
}