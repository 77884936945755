#contact {
  .container {
    @media only screen and (min-width: 738px) and (max-width: 823px) and (orientation: landscape){
      margin-top: 10%;
    }

    .social-links-container {
      // for Iphone 5 / SE Portrait
      @media only screen and (width: 320px){
        padding-top: 25%;
      }

      // for Iphone 5 / SE Landscape 
      @media only screen and (height: 320px) and (width: 568px) and (orientation: landscape){
        padding-top: 60%;
      }

      @media only screen and (min-width: 640px) and (max-width: 736px) and (orientation: landscape){
        padding-top: 50%;
      }

      // for Surface Duo
      @media only screen and (height: 540px) and (width: 720px) and (orientation: landscape){
        padding-top: 25%;
      }

      // for Galaxy Fold
      @media only screen and (height: 280px) and (width: 653px) and (orientation: landscape){
        padding-top: 70%;
      }

      h1 {
        color: $tertiary-color;
        font-size: 3.8rem;
        line-height: 3.8rem;
        letter-spacing: 0.12rem;
      }

      .links-container {
        .link-item {
          a {
            cursor: pointer;
            color: $tertiary-color;
            margin-left: 20px;
            letter-spacing: 0.1rem;
            transition: color 0.3s cubic-bezier(0,.5,0,1);

            &:hover {
              @apply text-red-600;
            }
          }
        }

        :nth-child(2) {
          margin: 10px 0;
        }
      }
    }

    .contact-form-container {
      @media only screen and (min-width: 768px) and (max-width: 825px) and (orientation: landscape){
        margin-top: 100px;
      }

      #contact-form {

        input[type=text], input[type=email] {
          font-weight: 500;
          color: $main-color;
          border-radius: 0;

          &::placeholder {
            color: #0101065d;
          }
        }

        textarea {
          font-weight: 500;
          color: $main-color;
          border-radius: 0;

          &::placeholder {
            color:  #0101065d;
          }
        }

        .send-btn { 
          background-color: $accent-color;
          border-radius: 0;
          color: $tertiary-color;
          letter-spacing: 0.12rem;
          position: relative;
          margin-right: 22px;
          transition: background-color, color 0.3s ease-in-out;

          &:hover {
            background-color: $tertiary-color;
            color: $main-color;
          }

          &::before {
            content: "";
            position: absolute;
            z-index: -2;
            width: 30px;
            background-color: inherit;
            top: 0;
            left: -10px;
            bottom: 0;
            transform: skewX(-22deg);
          }
        
          &::after {
            content: "";
            position: absolute;
            z-index: -2;
            width: 30px;
            background-color: inherit;
            top: 0;
            right: -10px;
            bottom: 0;
            transform: skewX(-22deg);
          }
        }
      }
    }
  }
}