#techs {
  position: relative;
  .container {
    padding-top: 0;

    @media only screen and (max-width: 640px) and (orientation: portrait) {
      padding-top: 30%;
    }

    @media only screen and (min-width: 640px) and (max-width: 823px) and (orientation: landscape) {
      margin-top: 12%;
      height: auto;
      justify-content: center;
      align-items: center;
    }
    
    // for Galaxy Fold - portrait
    @media only screen and (width: 280px) and (height: 653px) and (orientation: portrait) {
      padding-top: 40%;
    }

    // for iPhone 5/SE - landscape
    @media only screen and (width: 568px) and (height: 320px) and (orientation: landscape) {
      padding-top: 18%;
      height: auto;
      justify-content: space-between;
    }

    .tabs {
      position: relative;
      
      .tab {
        @apply text-lg relative cursor-pointer select-none;
        transition: transform 0.7s cubic-bezier(0,.5,0,1),
                    top 0.7s cubic-bezier(0,.5,0,1);
        color: $inactive-link-color;
        font-family: 'Teko', sans-serif;
        font-size: 48px;
        line-height: 48px;
        letter-spacing: 0.12rem;

        @media only screen and (max-width: 640px){
          font-size: 2rem;
          line-height: 2rem;
        }

        &:hover {
          color: $tertiary-color;
        }

        &:nth-child(2) {
          margin: 20px 0;

          @media only screen and (max-width: 640px){
            margin: 10px 0;
          }
        }

        &:nth-child(3) {
          @media only screen and (max-width: 640px){
            // margin-bottom: 20px;
          }
        }
        
        &.active {
          color: $tertiary-color;

          &::before {
            content: "";
            background-color: $accent-color;
            z-index: -2;
            width: 6px;
            height: 54px;
            position: absolute;
            left: -20px;
            top: -8px;
            transition: all 0.7s cubic-bezier(0,.5,0,1);

            @media only screen and (max-width: 640px){
              top: -6px;
              height: 2.5rem;
            }
          }  
        }
      }
    }
  }
}