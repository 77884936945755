.toast {
  position: absolute;
  z-index: 10;
  top: 15%;
  color: $tertiary-color;
  background-color: $secondary-color;
  width: max-content;
  padding: 8px 10px;
  font-size: 0.95rem;
  letter-spacing: 0.05rem;
  transition: all 0.5s cubic-bezier(0,.5,0,1);

  &.show {
    right: 4%;
  }

  &.hide {
    right: -50%;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    width: 30px;
    background-color: $secondary-color;
    top: 0;
    left: -10px;
    bottom: 0;
    transform: skewX(-22deg);
  }
}