// Text Colors Variables
$main-color: #010106;
$secondary-color: #4a4a4a;
$tertiary-color: #f0eff4; 
$accent-color: #b20000;
$inactive-link-color: #f0eff44d;
$text-watermark: rgba(240, 239, 244, 0.05);

:export {
  main-color: $main-color;
  secondary-color: $secondary-color;
  tertiary-color: $tertiary-color; 
  accent-color: $accent-color;
  inactive-link-color: $inactive-link-color;
  text-watermark: $text-watermark;
} 