nav {
  height: 90px;
  background-color: $main-color;
  
  .container {
    #brand-logo {
      height: 60px; 
      width: 80px;
  
      @media only screen and (max-width: 640px){
        height: 50px; 
        width: 70px;
      }
    }
  
    #main-nav {
      @media only screen and (max-width: 640px){
        display: none;
      }
  
      #indicator {
        position: absolute;
        bottom: -0.8rem;
        width: 18px;
        border-bottom: 3px solid $accent-color;
        transition: left 0.35s ease-in-out;
  
        @media only screen and (max-width: 640px){
          display: none;
        }
      }
  
      .link {
        @apply relative cursor-pointer leading-none select-none;
        color: $inactive-link-color;
        font-size: 18px;
        font-family: "Titillium Web", sans-serif;
        font-weight: normal;
        letter-spacing: 0.12rem;
        transition: all 0.3s cubic-bezier(0,.5,0,1);
  
        &:hover {
          color: $tertiary-color;
        }
  
        &.active {
          color: $tertiary-color;
          transition: width 0.3s cubic-bezier(0,.5,0,1),
          border-color 0.3s cubic-bezier(0,.5,0,1);
        }
  
        &:hover::after {
          width: 18px;
          border-color: $accent-color;
        }
      }
    }
    
    .menu-icon-container {
      display: none;
  
      @media only screen and (max-width: 640px){
        display: flex;
      }
    }
    
    #mobile-nav {
      position: absolute;
      height: 100vh;
      width: 100vw;
      left: 105%;
      top: 0;
      bottom: 0;
      z-index: -2;
      background-color: $main-color;
      transition: left 0.3s cubic-bezier(0,.5,0,1);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      @media only screen and (min-width: 640px){
        left: 120%;
      }
  
      &.open {
        left: 0;
      }
  
      .link {
        @apply relative cursor-pointer leading-none select-none;
        color: $inactive-link-color;
        font-size: 36px;
        font-family: "Titillium Web", sans-serif;
        font-weight: normal;
        letter-spacing: 0.12rem;
        transition: all 0.3s cubic-bezier(0,.5,0,1);
  
        &:hover {
          color: $tertiary-color;
        }
  
        &.active {
          color: $tertiary-color;
          transition: width 0.3s cubic-bezier(0,.5,0,1),
          border-color 0.3s cubic-bezier(0,.5,0,1);
          position: relative;
  
          &::after {
            content: "";
            background-color: $accent-color;
            position: absolute;
            height: 3px;
            width: 18px;
            left: 50%;
            bottom: -1rem;
            transform: translateX(-50%);
          }
        }
  
        &:hover::after {
          width: 18px;
          border-color: $accent-color;
        }
      }
    }
  }
}